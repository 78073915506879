import React from 'react'

const Pickup = () => {
  return (
    <div>
      In Progress.....
    </div>
  )
}

export default Pickup
