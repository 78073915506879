import React from 'react'

const DriverHomepage = () => {
  return (
    <div className='container'>
      Driver
    </div>
  )
}

export default DriverHomepage
